import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Public from '../views/Public.vue'
import PublicV2 from '../views/PublicV2.vue'
import Portal from '../views/Portal.vue'
import Login from '../views/Login.vue'
import PublicRoutes from "./public"
import PortalRoutes from "./portal"
import PublicV2Routes from "./publicv2"

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Public',
    component: Public,
    children: PublicRoutes
  },
  {
    path: '/v2',
    name: 'Public v2',
    component: PublicV2,
    children: PublicV2Routes
  },
  {
    path: '/portal',
    name: 'Portal',
    component: Portal,
    children: PortalRoutes
  },
]
import store from '@/store'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to,from,savedPosition) {
    return { top: 0 }
  }
})
router.beforeEach((to, from, next) => {
  if(to.path.search("/portal") === -1) next()
  if (to.name === 'Login') next()
  else if (store.state.isAuthentication) next()
  else {
    next({
      path: '/login'
    })
  }
  // next()

})


export default router
