import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import PR from '@/views/Portal/PR.vue'
import Service from '@/views/Portal/Service.vue'
import Contact from '@/views/Portal/Contact.vue'
import History from '@/views/Portal/History.vue'
import AboutUs from '@/views/Portal/AboutUs.vue'
import Home from '@/views/Portal/Home.vue'
import User from '@/views/Portal/User.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: 'pr',
    name: 'Portal PR',
    component: PR
  },
  {
    path: 'service',
    name: 'Portal Service',
    component: Service
  },
  {
    path: 'home',
    name: 'Portal Home',
    component: Home
  },
  {
    path: 'contact',
    name: 'Portal Contact',
    component: Contact
  },
  {
    path: 'history',
    name: 'Portal History',
    component: History
  },
  {
    path: 'about',
    name: 'Portal About Us',
    component: AboutUs
  },
  {
    path: 'user',
    name: 'Portal User',
    component: User
  },
]

export default routes