import { createStore } from 'vuex'

export default createStore({
  state: {
    userId: "",
    fullName: "",
    isAuthentication: false,
    local: {
      en: {
        companyName: "PoonTong Recycle Co., Ltd.",
        home: 'Home',
        aboutus: 'About Us',
        aboutusSubtitle: "Purchased used PET plastic to be recycled into plastic flakes and sells the product to a plastic processing plant for the next recycling process.",
        history: 'History',
        vision: 'Vision & Mission',
        visionSubtitle: 'Our vision is to operate a sustainable plastic recycle business',
        group: 'Group',
        service: 'Products & Services',
        pr: 'Public Relation',
        contact: 'Contact',
        footerContact: 'CONTACT INFO',
        footerRecent: 'RECENT WORK',
        socialSupport: 'Social Support, Create Learning Resources',
        vision1: 'We will be a plastic',
        vision2: 'recycling',
        vision3: 'company that operates business with',
        vision4: 'sustainability',
        vision5: 'to deliver great benefits to all stakeholders From the national level to the international level',
        mission: 'Mission',
        mission1: 'Sincere, Upright',
        mission2: 'Conduct business with customers, partners, shareholders, employees and business partners with sincerity, honesty, as an expert in the recycling industry.',
        mission3: 'One-Stop',
        mission4: 'Aiming to operate the business towards a fully integrated plastic recycling plant to become a Zero Plastic Waste Factory',
        mission5: 'Creative and Innovative',
        mission6: 'Continuously create and bring new innovations to the business. both in terms of machinery, technology and work processes',
        mission7: 'Create jobs, Generate income, Save the environment',
        mission8: 'Create jobs and income for the community Along with protecting the environment from the community level to the national level and international level',
        service1: 'PoonTong products are recycled using modern, standardized machines and have high production capacity. We have quality checks at every step. therefore get quality products Meet the standards that meet the needs and are always satisfied with all partners.',
        service2: 'Service',
        service3: 'Comprehensive consulting service for PET plastic bottle recycling business',
        service4: 'We have a quality team with expertise in machinery production process and sales management If you are interested and looking for a business partner We are happy to give advice and be in the same network. To move forward in the sustainable recycling business',
        product: 'Products',
        product1: 'PET plastic flakes, Rinsed in cold water',  
        product2: 'PET plastic flakes for hot water washing PET plastic flakes for hot water washing',  
        product3: 'Plastic Combine Crusher (Crushed Bottle Cap) Cold water washing and drying, crushed plastic aggregate (crushed water bottle caps), cold water washing and drying.',  
        product4: 'PET POPCORN',  
        product5: 'Fabric beater fabric beater fiber',  
        group1: '36 Moo11 Tumbon Bueng, Amper Kwoawsinrin, Surin District, Thailand', 
        group2: 'Business of recycling plastics/chemicals/synthetic fibers', 
        pr2: 'Read More',
        contact2: 'Contact Number',
        contact3: 'Email',
        footerText: '87 Moo 11, Bueng Sub-district, Khwao Sinrin District Surin Province, Thailand',
        serviceAndProduct:"Serivces and Products",
        homeText1: "We will be a plastic recycling",
        homeText2: "",
        homeText3: "company that operates business ",
        homeText4: "with",
        homeText5: "sustainability",
        homeText6: "to deliver great benefits",
        homeText7: "to all stakeholders",
        homeText8: "From the national level to the international level",
        homeHighlightHeader1:"Save Our Energy",
        homeHighlightHeader2:"Reduce Microplastic",
        homeHighlightHeader3:"Recycling is A Must",
        homeHighlightContent1:"Reduce energy consumption in the production of plastic bottles. new plastic production would use very high overall energy รncluding the use of large amounts of petroleum to obtain a single plastic bottle. (Recycling 1 ton of plastic bottles saves up to 3.8 barrels of crude oil.) Recycling plastic bottles directly reduces the energy used in production.",
        homeHighlightContent2:"In the long run, recycling of plastic bottles also contributes to the reduction of microplastics. caused by the decomposition of plastic At present, many appear from under the sea to Mount Everest. It also reduces the chances of land and water exposure to toxins from the decomposition of plastic bottles. that may occur in the future",
        homeHighlightContent3:"Recycling reduces the need for extracting, refining and processing raw materials all of which create air and water pollution. As recycling saves energy it also reduces greenhouse gas emissions, which helps to tackle climate change.",
        readMore: "Read More",
        homePurchase: "Purchase PET Plastic for recycle",
        homeSales: "Selling Recycled PET Plastic",
        homeVisit: "Visit Our Factory",
        homeFactoryAddress: "Poontong Factory Address 87 Moo 11, T.Bueng, A.Khevasinrindra, Surin District, Thailand",
        homeProcess: "Our Process",
        homeFactoryProcess: "Learn about various process in our factory",
        homeLearning: "Learn the effects",
        homeFactoryLearning: "Learn about the environmental impact if we don't recycle. What would it be like with our planet?",
        homeGroup: "Poontong Group Companies",
        homeCompanyName: "Poontong Fiber Co., Ltd.",
        homeCompanyAddress: "36 Moo 11, T.Bueng, A.Khevasinrindra, Surin District, Thailand",
        homeCompanyObjective: "Recycling Plastics/Chemicals/Synthetic Fibers",
        historyCompany: "Company Profile",
        aboutUsTitle: "About Us",
        aboutUsDescription:"Our factory purchases used PET plastic to be recycled through the recycling process until it turns into plastic flakes and sells the product to the plastic processing plant for the next recycling process.",
        activityImages: "Activity Images",
      },
      th: {
        companyName: "บริษัท พูนทองรีไซเคิล จำกัด",
        home: 'หน้าแรก',
        aboutus: 'เกี่ยวกับเรา',
        aboutusSubtitle: "รับซื้อพลาสติกประเภท PET ที่ใช้แล้ว เพื่อนำมารีไซเคิลเป็นเกล็ดพลาสติกและจำหน่ายผลิตภัณฑ์ให้กับโรงงานแปรรูปพลาสติกในกระบวนการรีไซเคิลขั้นถัดไป",
        history: 'ประวัติบริษัท',
        vision: 'วิสัยทัศน์และพันธกิจ',
        visionSubtitle: 'เราจะเป็นบริษัทรีไซเคิลพลาสติกที่ดำเนินธุรกิจด้วยความยั่งยืน',
        group: 'กลุ่มบริษัทในเครือพูนทอง',
        service: 'สินค้าและบริการ',
        pr: 'ข่าวประชาสัมพันธ์',
        contact: 'ติดต่อเรา',
        footerContact: 'ข้อมูล​ติดต่อเรา',
        footerRecent: 'ข่าวสารล่าสุด',
        socialSupport: 'สนับสนุนสังคม สร้างแหล่งเรียนรู้',
        vision1: 'เราจะเป็นบริษัท',
        vision2: 'รีไซเคิล',
        vision3: 'พลาสติกที่ดำเนินธุรกิจด้วยความ',
        vision4: 'ยั่งยืน',
        vision5: 'เพื่อส่งมอบประโยชน์ให้กับผู้มีส่วนได้ส่วนเสียทุกฝ่าย จากระดับประเทศสู่ระดับสากล',
        mission: 'พันธกิจ',
        mission1: 'จริงใจ เที่ยงตรง',
        mission2: 'ดำเนินธุรกิจต่อลูกค้า, คู่ค้า, ผู้ถือหุ้น, พนักงานและพันธมิตรทางธุรกิจด้วยความจริงใจ เที่ยงตรง อย่างผู้เชี่ยวชาญในวงการรีไซเคิล',
        mission3: 'ครบวงจร',
        mission4: 'มุ่งดำเนินกิจการสู่โรงงานรีไซเคิลพลาสติกแบบครบวงจร เพื่อเป็น Zero Plastic Waste Factory',
        mission5: 'สร้างสรรค์และนวัตกรรม',
        mission6: 'สร้างสรรค์และนำนวัตกรรมใหม่ๆมาใช้ในธุรกิจอย่างต่อเนื่อง ทั้งในด้านเครื่องจักร, เทคโนโลยีและขั้นตอนการทำงาน',
        mission7: 'สร้างงาน สร้างรายได้ รักษ์สิ่งแวดล้อม',
        mission8: 'สร้างงานและรายได้ให้แก่ชุมชน ควบคู่ไปกับการรักษาสิ่งแวดล้อมจากระดับชุมชนสู่ระดับประเทศ และระดับนานาชาติ',
        service1: 'สินค้าของพูนทองรีไซเคิลผลิตด้วยเครื่องจักรที่ทันสมัย มีมาตรฐาน และมีกำลังการผลิตสูง เรามีการตรวจสอบคุณภาพในทุกขั้นตอน จึงได้สินค้าที่มีคุณภาพ ได้มาตรฐานตรงตามความต้องการและเป็นที่พึงพอใจของคู่ค้าทุกท่านเสมอมา',
        service2: 'บริการ',
        service3: 'บริการให้คำปรึกษาด้านธุรกิจรีไซเคิลขวดพลาสติก PET แบบครบวงจร',
        service4: 'เรามีทีมงานคุณภาพ ที่มีความเชี่ยวชาญในด้านเครื่องจักร กระบวนการผลิต และบริหารงานขาย หากท่านสนใจและกำลังหาพันธมิตรทางธุรกิจ เรายินดีให้คำปรึกษาและเป็นเครือข่ายเดียวกัน เพื่อก้าวไปข้างหน้าในธุรกิจรีไซเคิลอย่างยั่งยืน',
        product: 'สินค้า',
        product1: 'เกล็ดพลาสติก PET ล้างน้ำเย็น',  
        product2: 'เกล็ดพลาสติก PET ล้างน้ำร้อน',  
        product3: 'พลาสติกรวมบด (ฝาขวดน้ำบด) ล้างน้ำเย็นและอบแห้ง',  
        product4: 'PET POPCORN',  
        product5: 'เส้นใยตีผ้า',  
        group1: 'บริษัท พูนทองไฟเบอร์ จำกัด เลขที่ 36 หมู่ที่ 11 ต.บึง อ.เขวาสินรินทร์ จ.สุรินทร์', 
        group2: 'ประกอบธุรกิจรีไซเคิลพลาสติก/เคมีภัณฑ์/เส้นใยสังเคราะห์', 
        pr2: 'อ่านต่อ',
        contact2: 'เบอร์ติดต่อ',
        contact3: 'อีเมล',
        footerText: 'เลขที่ 87 หมู่ 11 ตำบลบึง อำเภอเขวาสินรินทร์ จังหวัดสุรินทร์',
        serviceAndProduct:"สินค้าและบริการ",
        homeText1: "เราจะเป็นบริษัท",
        homeText2: "รีไซเคิล",
        homeText3: "พลาสติกที่ดำเนินธุรกิจ",
        homeText4: "ด้วยความ",
        homeText5: "ยั่งยืน",
        homeText6: "เพื่อส่งมอบประโยชน์",
        homeText7: "ให้กับผู้มีส่วนได้ส่วนเสียทุกฝ่าย",
        homeText8: "จากระดับประเทศสู่ระดับสากล",
        homeHighlightHeader1:"ลดการใช้พลังงาน",
        homeHighlightHeader2:"ลดไมโครพลาสติก",
        homeHighlightHeader3:"การรีไซเคิลเป็นสิ่งจำเป็น",
        homeHighlightContent1:"ลดการใช้พลังงานและวัตถุดิบในการผลิตขวดพลาสติก การผลิตพลาสติกใหม่ๆ ย่อมใช้พลังงานโดยรวมสูงมาก รวมถึงมีการใช้งานปิโตรเลียมจำนวนมหาศาลเพื่อการได้มาซึ่งขวดพลาสติกธรรมดาเพียงขวดเดียว (การรีไซเคิลขวดพลาสติก 1 ตัน ช่วยประหยัดน้ำมันดิบได้มากถึง 3.8 บาร์เรล) การรีไซเคิลขวดพลาสติกจึงช่วยลดพลังงานที่ใช้ในการผลิตโดยตรง",
        homeHighlightContent2:"ในระยะยาว การรีไซเคิลขวดพลาสติกยังมีส่วนช่วยในการลดไมโครพลาสติก ที่เกิดจากการสลายตัวของพลาสติก ที่ปัจจุบันปรากฎอยู่มากมายตั้งแต่ใต้ทะเลจนถึงยอดเขาเอเวอเรสต์ รวมถึงลดโอกาสที่พื้นดินและผืนน้ำจะได้รับสารพิษจากการย่อยสลายของขวดพลาสติก ที่อาจเกิดขึ้นในอนาคต",
        homeHighlightContent3:"การรีไซเคิลช่วยลดความจำเป็นในการสกัด กลั่น และแปรรูปวัตถุดิบ ซึ่งทั้งหมดนี้สร้างมลพิษทางอากาศและทางน้ำ เนื่องจากการรีไซเคิลช่วยประหยัดพลังงาน ยังช่วยลดการปล่อยก๊าซเรือนกระจก และช่วยลดผลกระทบที่เกี่ยวข้องกับการเปลี่ยนแปลงสภาพภูมิอากาศ",
        readMore: "อ่านต่อ",
        homePurchase: "รับซื้อพลาสติกรีไซเคิล",
        homeSales: "จำหน่ายพลาสติกรีไซเคิล",
        homeVisit: "เยี่ยมชมโรงงาน",
        homeFactoryAddress: "เยี่ยมชมโรงงานพูนทองรีไซเคิล 87 หมู่11 ตำบลบึง อำเภอเขวาสินรินทร์ จังหวัดสุรินทร์",
        homeProcess: "กระบวนการต่างๆ",
        homeFactoryProcess: "รู้จักกับกระบวนการต่างๆ ในโรงงานของเรา",
        homeLearning: "เรียนรู้ผลกระทบต่อสิ่งแวดล้อม",
        homeFactoryLearning: "เรียนรู้กระทบต่อสิ่งแวดล้อมหากเราไม่มีการรีไซเคิลโลกของเราจะเป็นอย่างไรบ้าง",
        homeGroup: "กลุ่มบริษัทในเครือพูนทอง (POONTONG GROUP)",
        homeCompanyName: "บริษัท พูนทองไฟเบอร์ จำกัด",
        homeCompanyAddress: "เลขที่ 36 หมู่ที่ 11 ต.บึง อ.เขวาสินรินทร์ จ.สุรินทร์",
        homeCompanyObjective: "ประกอบธุรกิจรีไซเคิลพลาสติก/เคมีภัณฑ์/เส้นใยสังเคราะห์",
        historyCompany: "ประวัติบริษัท",
        aboutUsTitle: "เกี่ยวกับเรา",
        aboutUsDescription:"โรงงานของเรารับซื้อพลาสติกประเภท PET ที่ใช้แล้ว เพื่อนำมารีไซเคิลผ่านขั้นตอนการรีไซเคิลจนออกมาเป็นเกล็ดพลาสติกและจำหน่ายผลิตภัณฑ์ให้กับโรงงานแปรรูปพลาสติกในกระบวนการรีไซเคิลขั้นถัดไป",
        activityImages: "รูปภาพกิจกรรม",
      }
    },
    language: "th"
  },
  getters: {
    localized: (state) => (key: any) => {
      try {
        //@ts-ignore
        return state.local[state.language][key]
      } catch (error) {
        return ""
      }
    }
  },
  mutations: {
    login: (state, payload) => {
      state.isAuthentication = true
    },
    logout: (state, payload) => {
      state.isAuthentication = false
    },
    switchLanguage(state, payload) {
      if (state.language === 'th') state.language = 'en'
      else state.language = 'th'
    },
    setUser(state, payload) {
      state.userId = payload.id,
      state.fullName = payload.fullName
    },
  },
  actions: {
  },
  modules: {
  }

})
