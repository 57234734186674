
import "@popperjs/core";
import "../node_modules/bootstrap/dist/js/bootstrap";

import { createApp } from 'vue'
import App from './App.vue'

import "@/extensions/date.extension";
import "@/extensions/string.extension";
import "@/extensions/number.extension";


import router from './router'
import store from './store'

import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import 'vue-select/dist/vue-select.css';
import 'aos/dist/aos.css'
import AOS from "aos"

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
import PRCarousel from "@/components/PRCarousel.vue";
import PRCarouselV2 from "@/components/PRCarouselV2.vue";
import TopNavigation from "@/components/TopNavigation.vue";
import SideNavigation from "@/components/SideNavigation.vue";
import Footer from "@/components/Footer.vue";
import JMUpload from "@/components/JMUpload.vue";
import JMLocale from "@/components/JMLocale.vue";
import JMLocaleH from "@/components/JMLocaleH.vue";
import JMLocaleHTML from "@/components/JMLocaleHTML.vue";
import JMRichText from "@/components/JMRichText.vue"
import vSelect from 'vue-select'
import JMUploadImages from "@/components/JMUploadImages.vue";
import JMIntroImage from "@/components/JMIntroImage.vue";
import JMIntroImageV2 from "@/components/JMIntroImageV2.vue";

AOS.init()
const app = createApp(App)
  // .use(Toast)
  .use(store)
  .use(router)

app.component('Toast', Toast)
app.component('QuillEditor', QuillEditor)
app.component("PRCarousel", PRCarousel);
app.component("PRCarouselV2", PRCarouselV2);
app.component("TopNavigation", TopNavigation);
app.component("SideNavigation", SideNavigation);
app.component("Footer", Footer);
app.component("JMUpload", JMUpload);
app.component('JMRichText', JMRichText)
app.component('v-select', vSelect)
app.component('JMUploadImages', JMUploadImages)
app.component('JMIntroImage', JMIntroImage)
app.component('JMIntroImageV2', JMIntroImageV2)
app.component('JMLocale', JMLocale)
app.component('JMLocaleH', JMLocaleH)
app.component('JMLocaleHTML', JMLocaleHTML)

app.mount('#app')

