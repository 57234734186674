import { DateTime } from "luxon";

export { }

declare global {
  interface Date {
    addDays(days: number, useThis?: boolean): Date;
    addMonths(days: number, useThis?: boolean): Date;
    toThaiShort(): String;
    toThaiNumeric(): String;
    toTime(): String;
    toThaiTime(): String;
    toWeekDay(): String;
    toThaiLong(): String;
    toThaiMonth(): String;
    toThaiMonthShort(): String;
    toThaiDayMonth(): String;
    toThaiMonthYear(): String;
    firstDayOfMonth(): Date;
    lastDayOfMonth(): Date;
    isToday(): boolean;
    clone(): Date;
    isAnotherMonth(date: Date): boolean;
    isWeekend(): boolean;
    isSameDate(date: Date): boolean;
    getStringDate(): String;
    /**
     * เทียบวันกับ Object Date คืนค่าเป็น Number จำนวนวันที่ห่างกัน
     * @param end first input to sum
     * @returns day difference
     */
    getDayDiff(end: Date): number
    /**
     * เทียบวันกับ Object Date คืนค่าเป็น Number จำนวนปีที่ห่างกัน
     * @param end first input to sum
     * @returns day difference
     */
    getYearDiff(end: Date): number
  }
  interface Number {
    intToThaiMonth(): String;
  }
}

Date.prototype.toWeekDay = function (): string {
  return this.toLocaleDateString('th-TH', { weekday: 'short' })
}

Date.prototype.getDayDiff = function (end: Date): number {
  let startDate = new Date(this);
  let endDate = new Date(end);
  if (endDate < startDate)
    return 0;

  var millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1);  // Start just after midnight
  endDate.setHours(23, 59, 59, 999);  // End just before midnight
  var diff = endDate.getTime() - startDate.getTime();  // Milliseconds between datetime objects
  var days = Math.ceil(diff / millisecondsPerDay);
  return days
}
Date.prototype.getYearDiff = function (end: Date): number {
  let startDate = new Date(this);
  let endDate = new Date(end);
  return endDate.getFullYear() - startDate.getFullYear()
}

Date.prototype.addDays = function (days: number): Date {
  if (!days) return this;
  let date = this;
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addMonths = function (months: number): Date {
  if (!months) return this;
  let date = this;
  date.setMonth(date.getMonth() + +months);
  return date;
};

Date.prototype.toThaiNumeric = function (): String {
  let dt = DateTime.fromJSDate(this)
  return dt.toFormat("dd/MM/yyyy")
}

Date.prototype.toThaiShort = function (): String {
  return this.toLocaleDateString('th-TH', { year: 'numeric', month: 'short', day: 'numeric' })
}

Date.prototype.toThaiLong = function (): String {
  return this.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric' })
}

Date.prototype.toThaiTime = function (): String {
  return this.toLocaleDateString('th-TH', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' })
}
Date.prototype.toTime = function (): String {
  let dt = DateTime.fromJSDate(this)
  return dt.toFormat("HH:mm")
}

Date.prototype.toThaiMonth = function (): String {
  return this.toLocaleDateString('th-TH', { month: 'long' })
}
Date.prototype.toThaiMonthShort = function (): String {
  return this.toLocaleDateString('th-TH', { month: 'short' })
}
Date.prototype.toThaiDayMonth = function (): String {
  return this.toLocaleDateString('th-TH', { day: 'numeric', month: 'short' })
}
Date.prototype.toThaiMonthYear = function (): String {
  return this.toLocaleDateString('th-TH', { month: 'short', year: "numeric" })
}



Date.prototype.firstDayOfMonth = function (): Date {
  var y = this.getFullYear(), m = this.getMonth();
  var firstDay = new Date(y, m, 1);
  return firstDay;
}
Date.prototype.lastDayOfMonth = function (): Date {
  var y = this.getFullYear(), m = this.getMonth();
  var lastDay = new Date(y, m + 1, 0);
  return lastDay;
}

Date.prototype.isToday = function (): boolean {
  let today = new Date();
  return this.isSameDate(today);
};

Date.prototype.clone = function (): Date {
  return new Date(+this);
};

Date.prototype.isAnotherMonth = function (date: Date): boolean {
  return date && this.getMonth() !== date.getMonth();
};

Date.prototype.isWeekend = function (): boolean {
  return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isSameDate = function (date: Date): boolean {
  return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
};

Date.prototype.getStringDate = function (): String {
  let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  let today = new Date();
  if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay()) {
    return "วันนี้";
  } else if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay() + 1) {
    return "พรุ่งนี้";
  } else if (this.getMonth() == today.getMonth() && this.getDay() == today.getDay() - 1) {
    return "เมื่อวาน";
  } else {
    return this.getDay() + ' เดือน ' + monthNames[this.getMonth()] + ' พ.ศ. ' + this.getFullYear();
    //return this.monthNames[this.getMonth()] + ' ' + this.getDay() + ', ' +  this.getFullYear();
  }
}


Number.prototype.intToThaiMonth = function (): String {
  let ret = ""
  //@ts-ignore
  let num = parseInt(this)
  switch (num) {
    case 1:
      ret = "มกราคม";
      break;
    case 2:
      ret = "กุมภาพันธ์";
      break;
    case 3:
      ret = "มีนาคม";
      break;
    case 4:
      ret = "เมษายน";
      break;
    case 5:
      ret = "พฤษภาคม";
      break;
    case 6:
      ret = "มิถุนายน";
      break;
    case 7:
      ret = "กรกฎาคม";
      break;
    case 8:
      ret = "สิงหาคม";
      break;
    case 9:
      ret = "กันยายน";
      break;
    case 10:
      ret = "ตุลาคม";
      break;
    case 11:
      ret = "พฤศจิกายน";
      break;
    case 12:
      ret = "ธันวาคม";
      break;
    default:
      ret = `${this}`;
      break;
  }
  return ret
}
