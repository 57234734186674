import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// import Home from '@/views/Public/Home.vue'
// import Contact from '@/views/Public/Contact.vue'
// import Group from '@/views/Public/Group.vue'
// import History from '@/views/Public/History.vue'
// import Service from '@/views/Public/Service.vue'
// import PR from '@/views/Public/PR.vue'
// import PRDetail from '@/views/Public/PRDetail.vue'
// import Vision from '@/views/Public/Vision.vue'
// import Aboutus from '@/views/Public/Aboutus.vue'

import Home from '@/views/PublicV2/Home.vue'
import Contact from '@/views/PublicV2/Contact.vue'
import Group from '@/views/PublicV2/Group.vue'
import History from '@/views/PublicV2/History.vue'
import Service from '@/views/PublicV2/Service.vue'
import PR from '@/views/PublicV2/PR.vue'
import PRDetail from '@/views/PublicV2/PRDetail.vue'
import Vision from '@/views/PublicV2/Vision.vue'
import Aboutus from '@/views/PublicV2/Aboutus.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Home',
    component: Home
  },
  {
    path: 'contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: 'group',
    name: 'Group',
    component: Group
  },
  {
    path: 'history',
    name: 'History',
    component: History
  },
  {
    path: 'service',
    name: 'Service',
    component: Service
  },
  {
    path: 'pr',
    name: 'PR',
    component: PR
  },
  {
    path: 'pr/:id',
    name: 'PR Detail',
    component: PRDetail
  },
  {
    path: 'vision',
    name: 'Vision',
    component: Vision
  },
  {
    path: 'aboutus',
    name: 'Aboutus',
    component: Aboutus
  },
]
export default routes