import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from '@/views/PublicV2/Home.vue'
import Contact from '@/views/PublicV2/Contact.vue'
import Group from '@/views/PublicV2/Group.vue'
import History from '@/views/PublicV2/History.vue'
import Service from '@/views/PublicV2/Service.vue'
import PR from '@/views/PublicV2/PR.vue'
import PRDetail from '@/views/PublicV2/PRDetail.vue'
import Vision from '@/views/PublicV2/Vision.vue'
import Aboutus from '@/views/PublicV2/Aboutus.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: 'Home V2',
    component: Home
  },
  {
    path: 'contact',
    name: 'Contact V2',
    component: Contact
  },
  {
    path: 'group',
    name: 'Group V2',
    component: Group
  },
  {
    path: 'history',
    name: 'History V2',
    component: History
  },
  {
    path: 'service',
    name: 'Service V2',
    component: Service
  },
  {
    path: 'pr',
    name: 'PR V2',
    component: PR
  },
  {
    path: 'pr/:id',
    name: 'PR Detail V2',
    component: PRDetail
  },
  {
    path: 'vision',
    name: 'Vision V2',
    component: Vision
  },
  {
    path: 'aboutus',
    name: 'Aboutus V2',
    component: Aboutus
  },
]
export default routes